<template>
  <t-modal name="register" class="z-45">
    <form @submit.prevent class="p-2 mt-4" autocomplete="off">
      <label for="email" class="mt-2 block text-sm font-medium text-gray-700">Email</label>
      <input v-model="email" type="email" name="email" id="email" autocomplete="off" class="mt-1 bg-gray-50 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
      <label for="passw" class="mt-2 block text-sm font-medium text-gray-700">Senha</label>
      <div class="relative mt-1">
        <input v-model="passw" :type="spass ? 'text' : 'password'" name="passw" id="passw" autocomplete="off" class="bg-gray-50 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-300 hover:text-primary">
          <svg v-if="!spass" @click="spass = true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
          <svg v-else @click="spass = false" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" /><path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" /></svg>
        </div>
      </div>
      <span class="py-4 block text-sm text-gray-500">Ao continuar, você aceita os <router-link @click.native="$modal.hide('register')" to="/termos" class="text-primary">Termos de Condições e Serviço</router-link>.</span>
      <button @click="createUser()" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Cadastrar</button>
      <p class="mt-6 text-center">Já possui conta? <span @click="$modal.hide('register'), $modal.show('login')" class="text-primary hover:text-primarydark font-semibold">Faça Login</span></p>
    </form>
  </t-modal>
</template>

<script>
import { auth, usersColl } from '@/firebase'

export default {
  data() {
    return {
      email: '',
      passw: '',
      spass: false,
    }
  },
  methods: {
    async createUser() {
      if(!this.$store.state.loading) this.$store.commit('setLoading', true)
      await auth.createUserWithEmailAndPassword(this.email, this.passw)
      .then(async (result) => {
        await usersColl.doc(result.user.uid).set({
          Permission: 'Client',
          CreatedAt: new Date(),
          Email: result.user.email,
        })
        .then(() => { this.$toast.success('Sucesso! Acesse seu e-mail para concluir seu cadastro'), this.$store.dispatch('fetchUserProfile', result.user) })
        .catch(() => { this.$toast.error('Falha ao salvar suas informações, tente novamente') });
        await result.user.sendEmailVerification()
      })
      .catch(err => {
        const errors = { 'auth/invalid-email': 'E-mail inválido, tente novamente', 'auth/email-already-in-use': 'E-mail já vinculado, tente novamente', 'auth/weak-password': 'Senha fraca, tente novamente' }
        this.$toast.error(errors[err.code])
      })
      this.$modal.hide('register')
      this.$modal.show('complete')
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  }
}
</script>